/**
 *		Sets:
 *			- max-width (defaults to global $max_width_content)
 *			- margin-right and margin-left to 'auto' (to center)
 *			- padding (top/bottom to 0, left/right defaults to global $padding_content)
 */
 @mixin maxwidth($max_width : $max_width_content, $padding : $padding_content) {
	max-width: $max_width;
	margin-right: auto;
	margin-left: auto;
	padding: 0 $padding;
}

/**
 *		Scale units gracefully on any property that supports calc()
 *
 *		element/.class/#id {
 *			// Scales font-size from 16px (at 320px) to 24px (at 640px)
 *			@include scale(font-size, 1rem, 20rem, 1.5rem, 40rem);
 *		}
 */
@mixin scale($prop, $start-size, $start-width, $end-size, $end-width) {
	#{$prop}: #{$start-size};
	@media (min-width: #{$start-width}) {
		#{$prop}: calc(#{$start-size} + #{strip-unit($end-size - $start-size)} * (100vw - #{$start-width}) / #{strip-unit($end-width - $start-width)});
	}
	@media (min-width: #{$end-width}) {
		#{$prop}: #{$end-size};
	}
}

/**
 *		Generate column-based layouts using flexbox
 *
 *		@include on the parent element and all direct children (of any type)
 *		will form a column layout at the specified breakpoints
 *
 *		element/.class/#id {
 *			// Forms a 2-column layout at 480px, 3-columns at 640px,
 *			// jumps back to 2 columns at 800px, etc.
 *			@include columns($margin_columns, $margin_rows, (
 *				30em : 2,
 *				40em : 3,
 *				50em : 2,
 *				60em : 3,
 *				70em : 4
 *			));
 *		}
 */
@mixin columns($margin_columns, $margin_rows, $breakpoint_map) {
	display: flex;
	flex-wrap: wrap;
	@if $margin_columns == 0 {
		margin-right: 0;
		margin-left: 0;
	} @else {
		margin-right: -#{$margin_columns / 2};
		margin-left: -#{$margin_columns / 2};
	}

	> * {
		display: block;
		@if $margin_columns == 0 {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		} @else {
			width: calc(100% - #{$margin_columns});
			margin-right: #{$margin_columns / 2};
			margin-left: #{$margin_columns / 2};
		}
		margin-bottom: $margin_rows;

		@each $breakpoint, $num_columns in $breakpoint_map {
			@media (min-width: $breakpoint) {
				flex: 1 1 auto;
				@if $margin_columns == 0 {
					width: calc(100% / #{$num_columns}.0001);
					max-width: calc(100% / #{$num_columns}.0001);
				} @else {
					width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
					max-width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
				}
			}
		}
	}
}

/**
 *		scrim-gradient
 *
 *		- Customizes the color stops for a much smoother transition
 *		- ONLY works from a single color to transparent
 *		- See https://css-tricks.com/easing-linear-gradients/ for details
 *
 *		element/.class/#id {
 *			@include scrim-gradient($direction, $color);
 *		}
 */
@mixin scrim-gradient($direction, $color) {
	background-image: linear-gradient($direction,
		$color 0%,
		rgba($color, .738) 19%,
		rgba($color, .541) 34%,
		rgba($color, .382) 47%,
		rgba($color, .278) 56.5%,
		rgba($color, .194) 65%,
		rgba($color, .126) 73%,
		rgba($color, .075) 80.2%,
		rgba($color, .042) 86.1%,
		rgba($color, .021) 91%,
		rgba($color, .008) 95.2%,
		rgba($color, .002) 98.2%,
		rgba($color, 0) 100%);
}

/**
 *		object-fit
 *
 *		Built-in polyfill for IE, Edge, Android < 5, Safari < 10, etc. using
 *		https://github.com/bfred-it/object-fit-images/ (via font-family attr)
 *
 *		element/.class/#id {
 *			@include object-fit();
 *		}
 */
@mixin object-fit($val, $position: null) {
 	object-fit: $val; // Opera Mobile, everything else
 	@if $position {
 		object-position: $position;
 		font-family: 'object-fit: #{$val}; object-position: #{$position}';
 	} @else {
 		font-family: 'object-fit: #{$val}';
	}
}