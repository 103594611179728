footer {
    font-family: "proxima-nova",sans-serif;
}

footer nav.footer_nav {
	display: none;
}

footer {
	border-top: 1px solid #d9d9d9;
	.contact_col {
		text-align: center;
		h5 {
			text-transform: uppercase;
			font-weight: 400;
			color: $blue;
			font-size: .875em;
			margin-bottom: 0;
			line-height: 1.25em;
			margin-top: 1.5em;
		}
		p {
			font-size: .875em;
			margin-top: .25em;
		}
		ul.social {
			list-style-type: none;
			margin: .5em 0;
			padding: 0;
			li {
				display: inline;
				a {
					text-decoration: none;
					opacity: .8;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
	nav {
		padding: 2em 0 1em;
		text-align: center;
		ul {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid #d9d9d9;
			li {
				border-top: 1px solid #d9d9d9;
				a {
					display: block;
					padding: .25em 0;
					color: #333;
					text-transform: uppercase;
					text-decoration: none;
					&:hover {
						color: darken($grey, 5%);
					}
				}
				ul {
					border-bottom: none;
					margin-bottom: 1em;
					li {
						border-top: 1px solid lighten(#d9d9d9, 10%);
						a {
							text-transform: none;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 650px) {
	footer {
		.contact_col {
			float: left;
			width: 35%;
			&.first {
				margin-left: 15%;
			}
			&.second {
				margin-right: 15%;
			}
		}
		nav.footer_utility {
			clear: both;
			ul {
				border-bottom: none;
				li {
					border-top: none;
					display: inline;
					padding: 2em 1em;
					a {
						display: inline;
						padding: 0;
					}
				}
			}
		}
	}
}

@media (min-width: 1000px) {
	footer {
		.contact_col {
			width: 25%;
			&.first {
				margin-left: 25%;
			}
			&.second {
				margin-right: 25%;
			}
		}
		nav.footer_nav {
			display: none;
		}
	}
}

@media (min-width: 1140px) {
	.contain {
		max-width: 1140px;
		margin: 0 auto;
	}
}