/******************************************************************************
 *  COLORS
 *****************************************************************************/
$color_primary                    : #003b5c;
$color_secondary                  : #94d94a;
$color_teritary                    : #f0b224;


$color_heading_text                : #333;
$color_white                       : #fff;


$colors_social_media : (
    facebook						: #1877f2,
    flickr							: #0063dc,
    google-plus						: #dc4e41,
    instagram						: #e4405f,
    linkedin						: #0077b5,
    medium							: #12100e,
    pinterest						: #bd081c,
    twitter							: #1da1f2,
    vimeo							: #1ab7ea,
    yelp							: #d32323,
    youtube							: #ff0000,
);

/******************************************************************************
 *  FONTS
 *****************************************************************************/
$font_stack_serif                   : "Lucida Bright", Georgia, TimesNewRoman, "Times New Roman", Times, Baskerville, serif;
$font_stack_sans_serif              : "Helvetica Neue", Helvetica, Arial, sans-serif;
$font_stack_cursive                 : cursive;
$font_stack_fantasy                 : fantasy;
$font_stack_monospace               : monospace;

$font_family_open_sans              : "Open Sans", $font_stack_sans_serif;
$font_family_header                 : $font_family_open_sans;
$font_family_body                   : $font_family_open_sans;


/******************************************************************************
 *  BREAKPOINTS
 *****************************************************************************/
$breakpoint_to_sidebar              : 50rem;
$breakpoint_to_desktop_nav          : 70rem;


/******************************************************************************
 *  CONTENT
 *****************************************************************************/
$max_width_content                  : 70.75rem;
$margin_sections                    : 5rem;
$padding_content                    : 1rem;

/*****************************************************************************
*   EXISTING FWA FOOTER VARIABLES
*****************************************************************************/

// Colors
$blue: #0077c0;
$green: #78a22f;
$yellow: #ffdf74;
$grey: #6d6d6d;