$banner_breakpoint: 63rem;

html {
    box-sizing: border-box;
}

*,
::after,
::before {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.blog-landing,
.cost-of-travel,
.local-pledge,
.company-feature {
    header {
        color: $color_secondary;
        font-weight: lighter;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-family: $font_family_open_sans;
        letter-spacing: .15rem;
    }

    p {
        font-size: 1rem;
        color: #000;
        font-weight: normal;
        line-height: 1.625;
        font-family: $font_family_open_sans;
    }
}

.blog-landing {

    .header {
        @include maxwidth();
        margin: 0 auto;
        padding: 1rem;

        div {
            display: flex;
            flex-direction: column;

            @media (min-width: 35rem) {
                flex-direction: row;
                align-items: flex-end;
            }
        }

        img {
            height: 3.75rem;
            margin-bottom: 1rem;
            margin-right: auto;

            @media (min-width: 35rem) {
                margin-bottom: 0;
            }
        }
    }

    .banner {
        .top-banner {
            height: 23.5rem;
            // overflow-y: clip;
            position: relative;
            min-height: 35rem;
            z-index: 2;

            @media (min-width: $banner_breakpoint) {
                min-height: 23.5rem;
                max-height: 23.5rem;
            }

            picture {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 2;

                img {
                    object-fit: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 100%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    width: 100%;
                }
            }

            .plane {
                // transform: translateX(100%);
                // opacity: 0;
                // visibility: hidden;

                position: absolute;
                right: 0;
                bottom: 3rem;
                z-index: 5;

                @media (min-width: 40rem) {
                    bottom: 4rem;
                }

                @media (min-width: $banner_breakpoint) {
                    bottom: 2rem;
                }

                img {
                    width: 14rem;
                    transform: scaleX(-1);

                    @media (min-width: 25rem) {
                        width: 18rem;
                    }

                    @media (min-width: 40rem) {
                        width: 20rem;
                    }

                    @media (min-width: $banner_breakpoint) {
                        width: 27rem;
                    }
                }
            }

            .slide {
                transition: .5s all ease-in;
                opacity: 1;
                transform: translateX(0%);
                visibility: visible;
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($color_primary, 0.9);
            z-index: 3;
        }

        .banner-text {
            @include maxwidth();
            padding: 3rem 2rem 0 2rem;
            position: absolute;
            z-index: 10;
            top: 0;
            font-family: $font_family_open_sans;

            @media (min-width: 40rem) {
                padding: 6.25rem 3rem 0 6rem;
            }

            @media (min-width: $banner_breakpoint) {
                padding-right: 1rem;
                padding-left: 1rem;
                position: relative;
            }

            .text-wrapper {
                @media (min-width: $banner_breakpoint) {
                    width: 27rem;
                }

                header {
                    font-size: 2.3rem;
                    letter-spacing: .25rem;

                    @media (min-width: 50rem) {
                        white-space: nowrap;
                    }
                }

                p {
                    color: $color_white;

                    span {
                        color: $color_teritary;
                        font-weight: bold;
                        font-style: italic;
                    }
                }
            }
        }

        .secondary-banner-text {
            display: flex;
            flex-direction: column-reverse;
            background-color: $color_secondary;
            position: relative;
            overflow: hidden;

            @media (min-width: 50rem) {
                flex-direction: row;
            }

            .image {
                flex: 1 1 60%;
                background-image: url("/images/AdobeStock_183128490.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 55% 0%;
                min-height: 25rem;
            }

            .text-wrapper {
                font-family: $font_family_open_sans;
                flex: 1 1 35%;
                padding: 7.25rem 4rem;
                position: relative;
                z-index: 2;

                .box {
                    width: 2.625rem;
                    height: 2.625rem;
                    background-color: rgba($color_primary, 1);
                    position: absolute;
                    left: 3rem;
                    top: -1.3125rem;
                    z-index: 5;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg);
                }

                .text {
                    @media (min-width: 50rem) {
                        max-width: 17rem;
                    }
                }

                .bg-image {
                    &::before {
                        content: "";
                        background-image: url("/images/AdobeStock_90970368-BW.jpg");
                        background-repeat: no-repeat;
                        background-size: 300% 125%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -1;
                        opacity: .1;
                        background-position: 87%;
                        background-size: 300% 200%;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            header {
                color: $color_primary;
                width: 90%;
            }

            p {
                span {
                    font-weight: bold;
                    color: $color_primary;
                }
            }
        }
    }

}

.cost-of-travel {
    padding: $margin_sections 0;
    background-color: #f8f8f8;
    text-align: center;

    header {
        @include maxwidth();
        color: $color_primary;
    }

    p {
        @include maxwidth(37.5rem);
        margin-left: auto;
        margin-right: auto;

        span {
            color: $color_primary;
            font-weight: bold;
        }
    }

    .icons {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 37.5rem;
        margin: 3rem auto;

        @media (min-width: 20rem) {
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 50rem) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
        }

        .icon-img {
            width: 5rem;
            display: flex;
            justify-content: center;
        }

        .icon-title {
            font-weight: bold;
            font-size: .875rem;
            color: $color_primary;
            font-family: $font_family_open_sans;
            text-transform: uppercase;
            letter-spacing: .15rem;
            margin-top: .25rem;
        }
    }

    .description-text {
        margin-bottom: 3rem;
    }

    .graph {
        @include maxwidth();
        margin-bottom: 4.375rem;

        .row {
            display: grid;
            align-items: center;
            grid-template-columns: 3rem 4rem auto;

            .airport {
                opacity: 0;
                font-size: .75rem;
                font-family: $font_family_open_sans;
                font-weight: lighter;
                letter-spacing: .2rem;
                color: #000;
                margin-right: .5rem;
            }

            .price {
                opacity: 0;
                font-size: 1.5rem;
                font-family: $font_family_open_sans;
                font-weight: bold;
                color: $color_primary;
                margin-right: 1rem;
                display: flex;
                justify-content: flex-start;
            }

            .appear {
                opacity: 1;
                transition: .5s all;
                transition-delay: .6s;
            }

            .bar {
                transform: translateX(-100%);
                opacity: 0;
                height: 1.9rem;
            }

            .bar-color {
                background-color: #f0b224;
                height: 100%;
            }

            .MDW {
                width: 85.75%;
            }

            .DTW {
                width: 75.28%;
            }

            .IND {
                width: 59.69%;
            }

            .FWA {
                width: 3.56%;
                min-width: 1rem;
            }

            .bar-blue {
                background-color: #00aeef;
            }

            .slide {
                transform: translateX(0);
                opacity: 1;
                transition: .5s all ease;
            }

            &:nth-child(2n) {
                .bar-color {
                    opacity: .75;
                }
            }

            &:nth-child(2) {
                .bar {
                    transition-delay: .1s;
                }
            }

            &:nth-child(3) {
                .bar {
                    transition-delay: .2s;
                }
            }

            &:nth-child(4) {
                .bar {
                    transition-delay: .3s;
                }
            }

            &:nth-child(5) {
                .bar {
                    transition-delay: .4s;
                }
            }
        }
    }

    .fwa-button {
        background-color: #f8f8f8;
    }
}

.local-pledge {
    position: relative;
    background-color: $color_teritary;

    .box {
        width: 2.625rem;
        height: 2.625rem;
        background-color: #f8f8f8;
        position: absolute;
        left: 3rem;
        top: -1.3125rem;
        transform: rotate(45deg);
        z-index: 5;

        @media (min-width: 50rem) {
            left: 11.375rem;
        }
    }

    .wrapper {
        position: relative;
        overflow: hidden;
        padding: 5rem 1rem;

        @media (min-width: 30rem) {
            padding: 6.25rem;
        }


        .content-wrapper {
            @include maxwidth();
            display: flex;
            flex-direction: column;

            @media (min-width: 50rem) {
                flex-direction: row;
            }
        }

        .bg-image {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0.1;
            height: 120%;
            z-index: 1;
            transform: translate(-50%, -50%);

            @media (min-width: 40rem) {
                width: 100%;
            }
        }

        .local-pledge-text,
        .pledge-form {
            flex: 1 1 50%;
        }

        header {
            color: $color_primary;
        }

        .local-pledge-text {
            position: relative;
            z-index: 2;

            @media (min-width: 30rem) {
                padding-right: 1.25rem;
            }
        }

        .pledge-bold {
            color: $color_primary;
            font-family: $font_family_open_sans;
            font-weight: bold;
            line-height: 1.625;
        }

        ul {
            padding-left: 1rem;

            li {
                font-size: 1rem;
                font-family: $font_family_open_sans;
                color: #000;
                font-weight: normal;
                line-height: 1.625;
            }
        }

        .plane {
            opacity: 0;
            transform: translateY(-200%);

            position: absolute;
            right: 0;
            top: 2.75rem;
            // transform: translateY(-50%);

            @media (min-width: 50rem) {
                top: 5.5rem;
            }

            img {
                width: 14rem;
                transform: rotate(180deg) scaleY(-1);

                @media (min-width: 50rem) {
                    width: 20.3125rem;
                }
            }

        }

        .slide {
            transition: .5s all;
            transition-delay: 1.25s;
            opacity: 1;
            transform: translateY(-50%)
        }

        .pledge-form {
            position: relative;
            z-index: 2;
            top: 2rem;

            .ctct-inline-form {
                label {
                    font-family: $font_family_open_sans;
                    font-size: .875rem;
                    font-weight: normal;
                    text-transform: uppercase;
                    letter-spacing: .15rem;
                    display: inline-block;
                }

                .ctct-form-embed.form_0 .ctct-form-defaults {
                    background-color: transparent !important;
                    padding-right: 0rem;
                }

                div.ctct-form-embed form.ctct-form-custom .ctct-form-required:before {
                    content: "*";
                    position: absolute;
                    top: 0px;
                    right: -10px;
                    left: auto;
                    color: #000;
                }

                div.ctct-form-embed form.ctct-form-custom input.ctct-form-element {
                    background-color: $color_teritary;
                    border: 2px solid white;
                    border-radius: 5px;
                }

                .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-button {
                    transition: .2s all ease-in-out;
                    font-size: .875rem;
                    font-weight: 600;
                    font-family: $font_family_open_sans;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    border-radius: 5px;
                }

                div.ctct-form-embed div.ctct-form-defaults p.ctct-form-footer {
                    display: none;
                }

                .ctct-form-header {
                    font-size: 1.5rem;
                    font-family: $font_family_open_sans;
                    color: $color_primary;
                    font-weight: lighter;
                    text-transform: uppercase;
                    letter-spacing: .15rem;
                }

                .ctct-form-text {
                    font-family: $font_family_open_sans;
                    color: #000;
                }

                .ctct-gdpr-text {
                    font-family: $font_family_open_sans;
                }
            }
        }

        .background-image {
            &::before {
                content: "";
                background-image: url("/images/AdobeStock_90970368-BW.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }

    .pledge-bottom-banner {
        padding: 3.75rem;
        background: $color_primary;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (min-width: 30rem) {
            flex-direction: row;
        }

        .headline {
            color: $color_secondary;
            font-size: 1.125rem;
            font-family: $font_family_open_sans;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: .15rem;
            margin-bottom: 2rem;
            white-space: nowrap;

            @media (min-width: 30rem) {
                margin-bottom: 0;
            }
        }

        .social-icons {
            display: flex;
            align-items: center;

            .icon {
                color: white;
                margin-left: 1.5rem;
                margin-right: 1.5rem;

                @media (min-width: 30rem) {
                    margin-bottom: 0;
                    margin-right: 0;
                }

                img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }

}

.company-feature {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    text-align: center;
    position: relative;

    .wrapper {
        @include maxwidth();

        header {
            color: $color_primary;
        }

        .numbers {
            font-family: $font_family_open_sans;
            color: $color_primary;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 3.75rem;
            line-height: 166px;
            font-size: 4rem;

            @media (min-width: 30rem) {
                font-size: 6rem;
            }

            @media (min-width: 50rem) {
                font-size: 8.625rem;
            }
        }

        .logo-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: .75rem;
            margin-bottom: 4.375rem;

            @media (min-width: 40rem) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 50rem) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width: 55rem) {
                grid-template-columns: repeat(5, 1fr);

            }

            @media (min-width: 60rem) {
                grid-template-columns: repeat(6, 1fr);
            }

            .logo {
                font-family: $font_family_open_sans;
                text-transform: uppercase;
                font-weight: bold;
                font-size: .625rem;
                border: 2px dashed #bfbfbf;
                height: 3.125rem;
                display: flex;
                justify-content: center;
                align-items: center;

                // @media (min-width: 60rem) {
                //     max-width: 7.25rem;
                // }

                img {
                    max-height: 6.25rem;
                    height: auto;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        a {
            width: 100%;
            max-width: 18.75rem;
        }
    }

    .plane {
        opacity: 0;
        transform: translateX(-100%);
        margin-top: 1rem;

        @media (min-width: 50rem) {
            position: absolute;
            bottom: 4rem;
            left: -6rem;
            margin-top: 0;
        }

        img {
            width: 100%;

            @media (min-width: 50rem) {
                width: 20.315rem;
            }
        }
    }

    .slide {
        transition: .5s all;
        transition-delay: 2.5s;
        opacity: 1;
        transform: translateX(0%)
    }
}

.max-width {
    @include maxwidth();
}

.fwa-button {
    transition: .2s all ease-in-out;
    display: inline-block;
    height: 100%;
    padding: .625rem .75rem;
    font-size: .875rem;
    font-weight: 600;
    font-family: $font_family_open_sans;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $color_primary;
    background-color: $color_white;
    border: 2px solid $color_secondary;
    border-radius: 5px;

    &:hover,
    &:focus {
        color: $color_white;
        background-color: $color_secondary;
        border-color: $color_secondary;
    }
}

.fwa-button-alt {
    transition: .2s all ease-in-out;
    display: inline-block;
    height: 100%;
    padding: .625rem .75rem;
    font-size: .875rem;
    font-weight: 600;
    font-family: $font_family_open_sans;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    background-color: #003b5c;
    border: 2px solid #003b5c;
    border-radius: 5px;

    &:hover,
    &:focus {
        color: #003b5c;
        background-color: white;
        border-color: white;
    }
}
